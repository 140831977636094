import { Grid } from "@mui/material";
import React from "react";
import Logoimg from "./Images/Honeywell-logo-web-175x55pxl.svg";
import "./Logo.css";

const Logo = () => {
  return (
    <Grid xs={12} md={12} sm={12} className="main-logo-background">
      <div className="logoStyle">
        <img src={Logoimg} alt="Honeywell Logo" className="logo-img" />
        <span className="divider"></span>
        <span className="tagline">AEROSPACE TECHNOLOGIES</span>
      </div>
    </Grid>
  );
};

export default Logo;