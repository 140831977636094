import React from "react";
import { useState,useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Grid } from "@mui/material";
import './TDPLanding.css';
import Login from "./Login";
import Logo from "./Logo";
import Footer from "./Footer";
import Videocomponent from "./Videocomponent";


const TDPLanding=()=>{
  const [data, setData] = useState([]);
  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        console.log(myjson);
        setData(myjson);
      });
  };
 
  useEffect(() => {
    getData();
  }, []);
    return(
      <div className="app-container">
     <header className="logo">
        <Logo/>
        </header>             
        <main className="main-content">
        <Marquee speed="100" delay="0">
        {data &&
          data.length > 0 &&
          data.map((val) => {
            return (
              <div>
                <span key={val.id} className="tdpnotice_text">{val.tdpnoticetext}</span>
              </div>
            );
          })}
      </Marquee>
        <Grid container spacing={2} className="twoboxes_grid">
          <Grid  md={5} xs={12} sm={12} >
          <Login/>
          </Grid>
        {/* </Grid> */}
        {/* <Grid container spacing={2}> */}
          <Grid  md={5} xs={12} sm={12} >
         <Videocomponent/>
          </Grid>
        </Grid>
        </main>
        <footer className="footer">
         <Footer/>
        </footer>
      </div>
    )
}


export default TDPLanding;