import React from "react";
import { useState, useEffect } from "react";
import { Grid ,Container} from "@mui/material";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Logoimg from "./Images/Honeywell-logo-web-175x55pxl.svg";
import Info from "./Images/InfoIcon.svg";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { TextField, FormLabel, MenuItem, Checkbox, Button, Autocomplete, FormHelperText,IconButton,Select } from "@mui/material";
import './Registrationform.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import success from "./Images/success.svg";
import errror from "./Images/error.svg";
import OTPInput from "react-otp-input";
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import Backdrop from '@material-ui/core/Backdrop';





// import SearchIcon from '@mui/icons-material/Search';
const Registrationform = () => {
  const [formValues, setFormValues] = React.useState(
    {
      "userInfo": {
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNo": "",
        "phoneCode": "",
        "busiSponserName": "",
        "busiSponserEmail": "",
        "honContact": "",
        "greenCardHolder": "",
        "password": ""
      },
      "companyInfo": {
        "companyName": "",
        "city": "",
        "state": "",
        "country": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "phoneNo": "",
        "phoneCode": "",
        "postalCode": "",
      },
      'pcitizenship': '',
      'scitizenship': ''
    });
   
    const [otp, setOtp] = useState("");
    const [resendTimer, setResendTimer] = useState(0);
    const [loading, setLoading] = useState(false);
    const [businessloading, setBusinessLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOTPChange = (otp) => {
      setOtp(otp);
    };

  const [countries, setcountries] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(['+1']);
  const [emailError, setEmailError] = useState(false);
  const [emailSponsError, setEmailSponsError] = useState(false);
  const [successPopup, setSuccessPopup] = React.useState(false);
  const [otpSuccessPopup, setOtpSuccessPopup] = React.useState(false);
  const [otpVerified, setOtpVerifiedPopup] = React.useState(false);
  const [bussinesSponserEmailVerified, setBussinesSponserEmailVerified] = React.useState(false);

  
  const [successDynamicPopup, setSuccessDynamicPopup] = React.useState(false);
  const [errorrPopup, setErrorrPopup] = React.useState(false);
  const [errorWarning, setErrorWarning] = React.useState(false);
  const [errorrOTPPopup, setErrorrOtpPopup] = React.useState(false);
  const [verifiedLdapUser, setVerifiedLdapUser] = React.useState(false);
  const [submitFlag, setSubmit] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [businessSponsorInitialValue,setBusinessSponsorInitialValue] = React.useState('');
  const [businessSponsorNameInitialValue,setBusinessSponsorNameInitialValue] = React.useState('');
  const [data, setData] = useState([]);
  const [displayGreenCard, setDisplayGreenCard] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(false);


  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        console.log('myjson',myjson);
        getCountryListfromApi(myjson)

        setData(myjson);

      });
  };


  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
   
  }, [resendTimer]);

  const handleResend = () => {
    // Implement your resend OTP logic here
    setResendTimer(10); // Set the timer to 60 seconds
    setErrorWarning(false);
    if(formValues.scitizenship != undefined && formValues.scitizenship != null){
      if(Object.keys(formValues.scitizenship).length != 0|| Object.keys(formValues.pcitizenship).length != 0){
        if( typeof(formValues.scitizenship) != 'string'){
          formValues.scitizenship = '';
          formValues.pcitizenship = '';
        } 
       
      }
    }
    const params = {
      EntityInfo: {
        EntityName: formValues.companyInfo.companyName ? formValues.companyInfo.companyName: '',
        EntityDUNSNumber: "",
        EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
        EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
        EntityCity: formValues.companyInfo.city ? formValues.companyInfo.city: '',
        EntityState: formValues.companyInfo.state ? formValues.companyInfo.state : '',
        EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
        EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
        EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
        EntityZip: formValues.companyInfo.postalCode ? formValues.companyInfo.postalCode:''
      },
      SubjectInfo: {
        EmailAddress: formValues.userInfo.email ? formValues.userInfo.email: '',
        BusinessSponsorEmail: formValues.userInfo.busiSponserEmail ? formValues.userInfo.busiSponserEmail: '',
        BusinessSponsorName: formValues.userInfo.busiSponserName ? formValues.userInfo.busiSponserName:'',
        Company: '',
        FirstName: formValues.userInfo.firstName ? formValues.userInfo.firstName:'',
        MiddleName: "",
        LastName: formValues.userInfo.lastName? formValues.userInfo.lastName:'',
        Title_Position: "",
        Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
        PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
        PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
        PrimaryCitizenship:  formValues.pcitizenship ?  formValues.pcitizenship:'',
        SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
        GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder:'',
        RegistrationStatus: "0"
      }
    }
  
 const url = data[0].registrationUrl;
    axios.post(url,params)

      .then(response => {
        console.log('response',response);
        console.log('response',response.data);
        if(response.data.status == "success"){
         if(response.data.message == 'sentOTP'){
          //dialog open

         }
        } else {
          setErrorMsg(response.data.message);
            setErrorrPopup(true);
            setSuccessDynamicPopup(false);
            setSuccessPopup(false)
        }

       
        
      })
      .catch(error => {
        alert(error)
    });
  };

  const getCountryData = () => {
    fetch("countrycode.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        console.log('myjson',myjson)

      });
  };

  const getCountryListfromApi = (dataList) => {

    const params = {
      EntityInfo: {
        EntityName: formValues.companyInfo.companyName,
        EntityDUNSNumber: "",
        EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
        EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
        EntityCity: formValues.companyInfo.city,
        EntityState: formValues.companyInfo.state,
        EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
        EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
        EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
        EntityZip: formValues.companyInfo.postalCode
      },
      SubjectInfo: {
        EmailAddress: formValues.userInfo.email,
        BusinessSponsorEmail: formValues.userInfo.busiSponserEmail,
        BusinessSponsorName: formValues.userInfo.busiSponserName,
        Company: '',
        FirstName: formValues.userInfo.firstName,
        MiddleName: "",
        LastName: formValues.userInfo.lastName,
        Title_Position: "",
        Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
        PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
        PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
        PrimaryCitizenship:  formValues.pcitizenship ? formValues.pcitizenship: '',
        SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
        GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder :'',
        RegistrationStatus: "4"
      }
    }
  
 const url = dataList[0].registrationUrl;
    axios.post(url,params)
      .then(data => {
        console.log('country',data.data);
        setcountries(data.data.countryData);
      })
      .catch(error =>   alert(error));

  }


  useEffect(() => {
    getData();
    getCountryData();
  }, []);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);


  useEffect(() => {
    // const url = 'https://api.first.org/data/v1/countries?region=africa&limit=3&pretty=true';
    // axios.get(url)

    //   .then(response => {
    //     setcountries(response.data.countries);
    //     console.log(response.data.countries);
    //   })
    //   .catch(error => {
    //     console.log('error fetching data ', error);
    //     setcountries([ 
    //        {name: 'Zimbabwe', code: 'ZW'} 
    //     ]);
    // });
    setcountries([
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Aland Islands', code: 'AX' },
      { name: 'Albania', code: 'AL' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'AndorrA', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Bahamas', code: 'BS' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belarus', code: 'BY' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Bosnia and Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Bouvet Island', code: 'BV' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory', code: 'IO' },
      { name: 'Brunei Darussalam', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cambodia', code: 'KH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cape Verde', code: 'CV' },
      { name: 'Cayman Islands', code: 'KY' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Congo', code: 'CG' },
      { name: 'Congo, The Democratic Republic of the', code: 'CD' },
      { name: 'Cook Islands', code: 'CK' },
      { name: 'Costa Rica', code: 'CR' },
      { name: 'Cote D\'Ivoire', code: 'CI' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czech Republic', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Egypt', code: 'EG' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands (Malvinas)', code: 'FK' },
      { name: 'Faroe Islands', code: 'FO' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
      { name: 'Holy See (Vatican City State)', code: 'VA' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Iceland', code: 'IS' },
      { name: 'India', code: 'IN' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Iran, Islamic Republic Of', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Japan', code: 'JP' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
      { name: 'Korea, Republic of', code: 'KR' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: 'Lao People\'S Democratic Republic', code: 'LA' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Libyan Arab Jamahiriya', code: 'LY' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macao', code: 'MO' },
      { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Maldives', code: 'MV' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia, Federated States of', code: 'FM' },
      { name: 'Moldova, Republic of', code: 'MD' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Montserrat', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Myanmar', code: 'MM' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Netherlands Antilles', code: 'AN' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Norway', code: 'NO' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Palestinian Territory, Occupied', code: 'PS' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Pitcairn', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Reunion', code: 'RE' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russian Federation', code: 'RU' },
      { name: 'RWANDA', code: 'RW' },
      { name: 'Saint Helena', code: 'SH' },
      { name: 'Saint Kitts and Nevis', code: 'KN' },
      { name: 'Saint Lucia', code: 'LC' },
      { name: 'Saint Pierre and Miquelon', code: 'PM' },
      { name: 'Saint Vincent and the Grenadines', code: 'VC' },
      { name: 'Samoa', code: 'WS' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Sao Tome and Principe', code: 'ST' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Serbia and Montenegro', code: 'CS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Svalbard and Jan Mayen', code: 'SJ' },
      { name: 'Swaziland', code: 'SZ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syrian Arab Republic', code: 'SY' },
      { name: 'Taiwan, Province of China', code: 'TW' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tanzania, United Republic of', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Timor-Leste', code: 'TL' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad and Tobago', code: 'TT' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Turks and Caicos Islands', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'Uganda', code: 'UG' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'United States Minor Outlying Islands', code: 'UM' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Uzbekistan', code: 'UZ' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Venezuela', code: 'VE' },
      { name: 'Viet Nam', code: 'VN' },
      { name: 'Virgin Islands, British', code: 'VG' },
      { name: 'Virgin Islands, U.S.', code: 'VI' },
      { name: 'Wallis and Futuna', code: 'WF' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' }
    ]);
    setPhoneNumber(
      [
        {
          "name": "Afghanistan",
          "dial_code": "+93",
          "code": "AF",
        },
        {
          "name": "Ascension Island",
          "dial_code": "+247",
          "code": "AC",
        },
        {
          "name": "Albania",
          "dial_code": "+355",
          "code": "AL",
        },
        {
          "name": "Algeria",
          "dial_code": "+213",
          "code": "DZ",
        },
        {
          "name": "Andorra",
          "dial_code": "+376",
          "code": "AD",
        },
        {
          "name": "Angola",
          "dial_code": "+244",
          "code": "AO",
        },
        {
          "name": "Anguilla",
          "dial_code": "+1264",
          "code": "AI",
        },
        {
          "name": "Antarctica",
          "dial_code": "+672",
          "code": "AQ",
        },
        {
          "name": "Antigua and Barbuda",
          "dial_code": "+1268",
          "code": "AG",
        },
        {
          "name": "Argentina",
          "dial_code": "+54",
          "code": "AR",
        },
        {
          "name": "Armenia",
          "dial_code": "+374",
          "code": "AM",
        },
        {
          "name": "Aruba",
          "dial_code": "+297",
          "code": "AW",
        },
        {
          "name": "Australia",
          "dial_code": "+61",
          "code": "AU",
        },
        {
          "name": "Austria",
          "dial_code": "+43",
          "code": "AT",
        },
        {
          "name": "Azerbaijan",
          "dial_code": "+994",
          "code": "AZ",
        },
        {
          "name": "Bahamas",
          "dial_code": "+1242",
          "code": "BS",
        },
        {
          "name": "Bahrain",
          "dial_code": "+973",
          "code": "BH",
        },
        {
          "name": "Bangladesh",
          "dial_code": "+880",
          "code": "BD",
        },
        {
          "name": "Barbados",
          "dial_code": "+1246",
          "code": "BB",
        },
        {
          "name": "Belarus",
          "dial_code": "+375",
          "code": "BY",
        },
        {
          "name": "Belgium",
          "dial_code": "+32",
          "code": "BE",
        },
        {
          "name": "Belize",
          "dial_code": "+501",
          "code": "BZ",
        },
        {
          "name": "Benin",
          "dial_code": "+229",
          "code": "BJ",
        },
        {
          "name": "Bermuda",
          "dial_code": "+1441",
          "code": "BM",
        },
        {
          "name": "Bhutan",
          "dial_code": "+975",
          "code": "BT",
        },
        {
          "name": "Bolivia, Plurinational State of",
          "dial_code": "+591",
          "code": "BO",
        },
        {
          "name": "Bosnia and Herzegovina",
          "dial_code": "+387",
          "code": "BA",
        },
        {
          "name": "Botswana",
          "dial_code": "+267",
          "code": "BW",
        },
        {
          "name": "Brazil",
          "dial_code": "+55",
          "code": "BR",
        },
        {
          "name": "British Indian Ocean Territory",
          "dial_code": "+246",
          "code": "IO",
        },
        {
          "name": "Brunei Darussalam",
          "dial_code": "+673",
          "code": "BN",
        },
        {
          "name": "Bulgaria",
          "dial_code": "+359",
          "code": "BG",
        },
        {
          "name": "Burkina Faso",
          "dial_code": "+226",
          "code": "BF",
        },
        {
          "name": "Burundi",
          "dial_code": "+257",
          "code": "BI",
        },
        {
          "name": "Cambodia",
          "dial_code": "+855",
          "code": "KH"
        },
        {
          "name": "Cameroon",
          "dial_code": "+237",
          "code": "CM"
        },
        {
          "name": "United States",
          "dial_code": "+1",
          "code": "US"
        },
        {
          "name": "Canada",
          "dial_code": "+1",
          "code": "CA"
        },
        {
          "name": "Cape Verde",
          "dial_code": "+238",
          "code": "CV"
        },
        {
          "name": "Cayman Islands",
          "dial_code": "+ 345",
          "code": "KY"
        },
        {
          "name": "Central African Republic",
          "dial_code": "+236",
          "code": "CF"
        },
        {
          "name": "Chad",
          "dial_code": "+235",
          "code": "TD"
        },
        {
          "name": "Chile",
          "dial_code": "+56",
          "code": "CL"
        },
        {
          "name": "China",
          "dial_code": "+86",
          "code": "CN"
        },
        {
          "name": "Christmas Island",
          "dial_code": "+61",
          "code": "CX"
        },
        {
          "name": "Cocos (Keeling) Islands",
          "dial_code": "+61",
          "code": "CC"
        },
        {
          "name": "Colombia",
          "dial_code": "+57",
          "code": "CO"
        },
        {
          "name": "Comoros",
          "dial_code": "+269",
          "code": "KM"
        },
        {
          "name": "Congo",
          "dial_code": "+242",
          "code": "CG"
        },
        {
          "name": "Congo, The Democratic Republic of the Congo",
          "dial_code": "+243",
          "code": "CD"
        },
        {
          "name": "Cook Islands",
          "dial_code": "+682",
          "code": "CK"
        },
        {
          "name": "Costa Rica",
          "dial_code": "+506",
          "code": "CR"
        },
        {
          "name": "Cote d'Ivoire",
          "dial_code": "+225",
          "code": "CI"
        },
        {
          "name": "Croatia",
          "dial_code": "+385",
          "code": "HR"
        },
        {
          "name": "Cuba",
          "dial_code": "+53",
          "code": "CU"
        },
        {
          "name": "Cyprus",
          "dial_code": "+357",
          "code": "CY"
        },
        {
          "name": "Czech Republic",
          "dial_code": "+420",
          "code": "CZ"
        },
        {
          "name": "Denmark",
          "dial_code": "+45",
          "code": "DK"
        },
        {
          "name": "Djibouti",
          "dial_code": "+253",
          "code": "DJ"
        },
        {
          "name": "Dominica",
          "dial_code": "+1767",
          "code": "DM"
        },
        {
          "name": "Dominican Republic",
          "dial_code": "+1849",
          "code": "DO"
        },
        {
          "name": "Ecuador",
          "dial_code": "+593",
          "code": "EC"
        },
        {
          "name": "Egypt",
          "dial_code": "+20",
          "code": "EG"
        },
        {
          "name": "El Salvador",
          "dial_code": "+503",
          "code": "SV"
        },
        {
          "name": "Equatorial Guinea",
          "dial_code": "+240",
          "code": "GQ"
        },
        {
          "name": "Eritrea",
          "dial_code": "+291",
          "code": "ER"
        },
        {
          "name": "Estonia",
          "dial_code": "+372",
          "code": "EE"
        },
        {
          "name": "Ethiopia",
          "dial_code": "+251",
          "code": "ET"
        },
        {
          "name": "Falkland Islands (Malvinas)",
          "dial_code": "+500",
          "code": "FK"
        },
        {
          "name": "Faroe Islands",
          "dial_code": "+298",
          "code": "FO"
        },
        {
          "name": "Fiji",
          "dial_code": "+679",
          "code": "FJ"
        },
        {
          "name": "Finland",
          "dial_code": "+358",
          "code": "FI"
        },
        {
          "name": "France",
          "dial_code": "+33",
          "code": "FR"
        },
        {
          "name": "French Guiana",
          "dial_code": "+594",
          "code": "GF"
        },
        {
          "name": "French Polynesia",
          "dial_code": "+689",
          "code": "PF"
        },
        {
          "name": "Gabon",
          "dial_code": "+241",
          "code": "GA"
        },
        {
          "name": "Gambia",
          "dial_code": "+220",
          "code": "GM"
        },
        {
          "name": "Georgia",
          "dial_code": "+995",
          "code": "GE"
        },
        {
          "name": "Germany",
          "dial_code": "+49",
          "code": "DE"
        },
        {
          "name": "Ghana",
          "dial_code": "+233",
          "code": "GH"
        },
        {
          "name": "Gibraltar",
          "dial_code": "+350",
          "code": "GI"
        },
        {
          "name": "Greece",
          "dial_code": "+30",
          "code": "GR"
        },
        {
          "name": "Greenland",
          "dial_code": "+299",
          "code": "GL"
        },
        {
          "name": "Grenada",
          "dial_code": "+1473",
          "code": "GD"
        },
        {
          "name": "Guadeloupe",
          "dial_code": "+590",
          "code": "GP"
        },
        {
          "name": "Guam",
          "dial_code": "+1671",
          "code": "GU"
        },
        {
          "name": "Guatemala",
          "dial_code": "+502",
          "code": "GT"
        },
        {
          "name": "Guernsey",
          "dial_code": "+44",
          "code": "GG"
        },
        {
          "name": "Guinea",
          "dial_code": "+224",
          "code": "GN"
        },
        {
          "name": "Guinea-Bissau",
          "dial_code": "+245",
          "code": "GW"
        },
        {
          "name": "Guyana",
          "dial_code": "+595",
          "code": "GY"
        },
        {
          "name": "Haiti",
          "dial_code": "+509",
          "code": "HT"
        },
        {
          "name": "Holy See (Vatican City State)",
          "dial_code": "+379",
          "code": "VA"
        },
        {
          "name": "Honduras",
          "dial_code": "+504",
          "code": "HN"
        },
        {
          "name": "Hong Kong",
          "dial_code": "+852",
          "code": "HK"
        },
        {
          "name": "Hungary",
          "dial_code": "+36",
          "code": "HU"
        },
        {
          "name": "Iceland",
          "dial_code": "+354",
          "code": "IS"
        },
        {
          "name": "India",
          "dial_code": "+91",
          "code": "IN"
        },
        {
          "name": "Indonesia",
          "dial_code": "+62",
          "code": "ID"
        },
        {
          "name": "Iran, Islamic Republic of Persian Gulf",
          "dial_code": "+98",
          "code": "IR"
        },
        {
          "name": "Iraq",
          "dial_code": "+964",
          "code": "IQ"
        },
        {
          "name": "Ireland",
          "dial_code": "+353",
          "code": "IE"
        },
        {
          "name": "Isle of Man",
          "dial_code": "+44",
          "code": "IM"
        },
        {
          "name": "Israel",
          "dial_code": "+972",
          "code": "IL"
        },
        {
          "name": "Italy",
          "dial_code": "+39",
          "code": "IT"
        },
        {
          "name": "Jamaica",
          "dial_code": "+1876",
          "code": "JM"
        },
        {
          "name": "Japan",
          "dial_code": "+81",
          "code": "JP"
        },
        {
          "name": "Jersey",
          "dial_code": "+44",
          "code": "JE"
        },
        {
          "name": "Jordan",
          "dial_code": "+962",
          "code": "JO"
        },
        {
          "name": "Kazakhstan",
          "dial_code": "+77",
          "code": "KZ"
        },
        {
          "name": "Kenya",
          "dial_code": "+254",
          "code": "KE"
        },
        {
          "name": "Kiribati",
          "dial_code": "+686",
          "code": "KI"
        },
        {
          "name": "Korea, Democratic People's Republic of Korea",
          "dial_code": "+850",
          "code": "KP"
        },
        {
          "name": "Korea, Republic of South Korea",
          "dial_code": "+82",
          "code": "KR"
        },
        {
          "name": "Kuwait",
          "dial_code": "+965",
          "code": "KW"
        },
        {
          "name": "Kyrgyzstan",
          "dial_code": "+996",
          "code": "KG"
        },
        {
          "name": "Laos",
          "dial_code": "+856",
          "code": "LA"
        },
        {
          "name": "Latvia",
          "dial_code": "+371",
          "code": "LV"
        },
        {
          "name": "Lebanon",
          "dial_code": "+961",
          "code": "LB"
        },
        {
          "name": "Lesotho",
          "dial_code": "+266",
          "code": "LS"
        },
        {
          "name": "Liberia",
          "dial_code": "+231",
          "code": "LR"
        },
        {
          "name": "Libyan Arab Jamahiriya",
          "dial_code": "+218",
          "code": "LY"
        },
        {
          "name": "Liechtenstein",
          "dial_code": "+423",
          "code": "LI"
        },
        {
          "name": "Lithuania",
          "dial_code": "+370",
          "code": "LT"
        },
        {
          "name": "Luxembourg",
          "dial_code": "+352",
          "code": "LU"
        },
        {
          "name": "Macao",
          "dial_code": "+853",
          "code": "MO"
        },
        {
          "name": "Macedonia",
          "dial_code": "+389",
          "code": "MK"
        },
        {
          "name": "Madagascar",
          "dial_code": "+261",
          "code": "MG"
        },
        {
          "name": "Malawi",
          "dial_code": "+265",
          "code": "MW"
        },
        {
          "name": "Malaysia",
          "dial_code": "+60",
          "code": "MY"
        },
        {
          "name": "Maldives",
          "dial_code": "+960",
          "code": "MV"
        },
        {
          "name": "Mali",
          "dial_code": "+223",
          "code": "ML"
        },
        {
          "name": "Malta",
          "dial_code": "+356",
          "code": "MT"
        },
        {
          "name": "Marshall Islands",
          "dial_code": "+692",
          "code": "MH"
        },
        {
          "name": "Martinique",
          "dial_code": "+596",
          "code": "MQ"
        },
        {
          "name": "Mauritania",
          "dial_code": "+222",
          "code": "MR"
        },
        {
          "name": "Mauritius",
          "dial_code": "+230",
          "code": "MU"
        },
        {
          "name": "Mayotte",
          "dial_code": "+262",
          "code": "YT"
        },
        {
          "name": "Mexico",
          "dial_code": "+52",
          "code": "MX"
        },
        {
          "name": "Micronesia, Federated States of Micronesia",
          "dial_code": "+691",
          "code": "FM"
        },
        {
          "name": "Moldova",
          "dial_code": "+373",
          "code": "MD"
        },
        {
          "name": "Monaco",
          "dial_code": "+377",
          "code": "MC"
        },
        {
          "name": "Mongolia",
          "dial_code": "+976",
          "code": "MN"
        },
        {
          "name": "Montenegro",
          "dial_code": "+382",
          "code": "ME"
        },
        {
          "name": "Montserrat",
          "dial_code": "+1664",
          "code": "MS"
        },
        {
          "name": "Morocco",
          "dial_code": "+212",
          "code": "MA"
        },
        {
          "name": "Mozambique",
          "dial_code": "+258",
          "code": "MZ"
        },
        {
          "name": "Myanmar",
          "dial_code": "+95",
          "code": "MM"
        },
        {
          "name": "Namibia",
          "dial_code": "+264",
          "code": "NA"
        },
        {
          "name": "Nauru",
          "dial_code": "+674",
          "code": "NR"
        },
        {
          "name": "Nepal",
          "dial_code": "+977",
          "code": "NP"
        },
        {
          "name": "Netherlands",
          "dial_code": "+31",
          "code": "NL"
        },
        {
          "name": "New Caledonia",
          "dial_code": "+687",
          "code": "NC"
        },
        {
          "name": "New Zealand",
          "dial_code": "+64",
          "code": "NZ"
        },
        {
          "name": "Nicaragua",
          "dial_code": "+505",
          "code": "NI"
        },
        {
          "name": "Niger",
          "dial_code": "+227",
          "code": "NE"
        },
        {
          "name": "Nigeria",
          "dial_code": "+234",
          "code": "NG"
        },
        {
          "name": "Niue",
          "dial_code": "+683",
          "code": "NU"
        },
        {
          "name": "Norfolk Island",
          "dial_code": "+672",
          "code": "NF"
        },
        {
          "name": "Northern Mariana Islands",
          "dial_code": "+1670",
          "code": "MP"
        },
        {
          "name": "Norway",
          "dial_code": "+47",
          "code": "NO"
        },
        {
          "name": "Oman",
          "dial_code": "+968",
          "code": "OM"
        },
        {
          "name": "Pakistan",
          "dial_code": "+92",
          "code": "PK"
        },
        {
          "name": "Palau",
          "dial_code": "+680",
          "code": "PW"
        },
        {
          "name": "Palestinian Territory, Occupied",
          "dial_code": "+970",
          "code": "PS"
        },
        {
          "name": "Panama",
          "dial_code": "+507",
          "code": "PA"
        },
        {
          "name": "Papua New Guinea",
          "dial_code": "+675",
          "code": "PG"
        },
        {
          "name": "Paraguay",
          "dial_code": "+595",
          "code": "PY"
        },
        {
          "name": "Peru",
          "dial_code": "+51",
          "code": "PE"
        },
        {
          "name": "Philippines",
          "dial_code": "+63",
          "code": "PH"
        },
        {
          "name": "Pitcairn",
          "dial_code": "+872",
          "code": "PN"
        },
        {
          "name": "Poland",
          "dial_code": "+48",
          "code": "PL"
        },
        {
          "name": "Portugal",
          "dial_code": "+351",
          "code": "PT"
        },
        {
          "name": "Puerto Rico",
          "dial_code": "+1939",
          "code": "PR"
        },
        {
          "name": "Qatar",
          "dial_code": "+974",
          "code": "QA"
        },
        {
          "name": "Romania",
          "dial_code": "+40",
          "code": "RO"
        },
        {
          "name": "Russia",
          "dial_code": "+7",
          "code": "RU"
        },
        {
          "name": "Rwanda",
          "dial_code": "+250",
          "code": "RW"
        },
        {
          "name": "Reunion",
          "dial_code": "+262",
          "code": "RE"
        },
        {
          "name": "Saint Barthelemy",
          "dial_code": "+590",
          "code": "BL"
        },
        {
          "name": "Saint Helena, Ascension and Tristan Da Cunha",
          "dial_code": "+290",
          "code": "SH"
        },
        {
          "name": "Saint Kitts and Nevis",
          "dial_code": "+1869",
          "code": "KN"
        },
        {
          "name": "Saint Lucia",
          "dial_code": "+1758",
          "code": "LC"
        },
        {
          "name": "Saint Martin",
          "dial_code": "+590",
          "code": "MF"
        },
        {
          "name": "Saint Pierre and Miquelon",
          "dial_code": "+508",
          "code": "PM"
        },
        {
          "name": "Saint Vincent and the Grenadines",
          "dial_code": "+1784",
          "code": "VC"
        },
        {
          "name": "Samoa",
          "dial_code": "+685",
          "code": "WS"
        },
        {
          "name": "San Marino",
          "dial_code": "+378",
          "code": "SM"
        },
        {
          "name": "Sao Tome and Principe",
          "dial_code": "+239",
          "code": "ST"
        },
        {
          "name": "Saudi Arabia",
          "dial_code": "+966",
          "code": "SA"
        },
        {
          "name": "Senegal",
          "dial_code": "+221",
          "code": "SN"
        },
        {
          "name": "Serbia",
          "dial_code": "+381",
          "code": "RS"
        },
        {
          "name": "Seychelles",
          "dial_code": "+248",
          "code": "SC"
        },
        {
          "name": "Sierra Leone",
          "dial_code": "+232",
          "code": "SL"
        },
        {
          "name": "Singapore",
          "dial_code": "+65",
          "code": "SG"
        },
        {
          "name": "Slovakia",
          "dial_code": "+421",
          "code": "SK"
        },
        {
          "name": "Slovenia",
          "dial_code": "+386",
          "code": "SI"
        },
        {
          "name": "Solomon Islands",
          "dial_code": "+677",
          "code": "SB"
        },
        {
          "name": "Somalia",
          "dial_code": "+252",
          "code": "SO"
        },
        {
          "name": "South Africa",
          "dial_code": "+27",
          "code": "ZA"
        },
        {
          "name": "South Sudan",
          "dial_code": "+211",
          "code": "SS"
        },
        {
          "name": "South Georgia and the South Sandwich Islands",
          "dial_code": "+500",
          "code": "GS"
        },
        {
          "name": "Spain",
          "dial_code": "+34",
          "code": "ES"
        },
        {
          "name": "Sri Lanka",
          "dial_code": "+94",
          "code": "LK"
        },
        {
          "name": "Sudan",
          "dial_code": "+249",
          "code": "SD"
        },
        {
          "name": "Suriname",
          "dial_code": "+597",
          "code": "SR"
        },
        {
          "name": "Svalbard and Jan Mayen",
          "dial_code": "+47",
          "code": "SJ"
        },
        {
          "name": "Swaziland",
          "dial_code": "+268",
          "code": "SZ"
        },
        {
          "name": "Sweden",
          "dial_code": "+46",
          "code": "SE"
        },
        {
          "name": "Switzerland",
          "dial_code": "+41",
          "code": "CH"
        },
        {
          "name": "Syrian Arab Republic",
          "dial_code": "+963",
          "code": "SY"
        },
        {
          "name": "Taiwan",
          "dial_code": "+886",
          "code": "TW"
        },
        {
          "name": "Tajikistan",
          "dial_code": "+992",
          "code": "TJ"
        },
        {
          "name": "Tanzania, United Republic of Tanzania",
          "dial_code": "+255",
          "code": "TZ"
        },
        {
          "name": "Thailand",
          "dial_code": "+66",
          "code": "TH"
        },
        {
          "name": "Timor-Leste",
          "dial_code": "+670",
          "code": "TL"
        },
        {
          "name": "Togo",
          "dial_code": "+228",
          "code": "TG"
        },
        {
          "name": "Tokelau",
          "dial_code": "+690",
          "code": "TK"
        },
        {
          "name": "Tonga",
          "dial_code": "+676",
          "code": "TO"
        },
        {
          "name": "Trinidad and Tobago",
          "dial_code": "+1868",
          "code": "TT"
        },
        {
          "name": "Tunisia",
          "dial_code": "+216",
          "code": "TN"
        },
        {
          "name": "Turkey",
          "dial_code": "+90",
          "code": "TR"
        },
        {
          "name": "Turkmenistan",
          "dial_code": "+993",
          "code": "TM"
        },
        {
          "name": "Turks and Caicos Islands",
          "dial_code": "+1649",
          "code": "TC"
        },
        {
          "name": "Tuvalu",
          "dial_code": "+688",
          "code": "TV"
        },
        {
          "name": "Uganda",
          "dial_code": "+256",
          "code": "UG"
        },
        {
          "name": "Ukraine",
          "dial_code": "+380",
          "code": "UA"
        },
        {
          "name": "United Arab Emirates",
          "dial_code": "+971",
          "code": "AE"
        },
        {
          "name": "United Kingdom",
          "dial_code": "+44",
          "code": "GB"
        },
        
        {
          "name": "Uruguay",
          "dial_code": "+598",
          "code": "UY"
        },
        {
          "name": "Uzbekistan",
          "dial_code": "+998",
          "code": "UZ"
        },
        {
          "name": "Vanuatu",
          "dial_code": "+678",
          "code": "VU"
        },
        {
          "name": "Venezuela, Bolivarian Republic of Venezuela",
          "dial_code": "+58",
          "code": "VE"
        },
        {
          "name": "Vietnam",
          "dial_code": "+84",
          "code": "VN"
        },
        {
          "name": "Virgin Islands, British",
          "dial_code": "+1284",
          "code": "VG"
        },
        {
          "name": "Virgin Islands, U.S.",
          "dial_code": "+1340",
          "code": "VI"
        },
        {
          "name": "Wallis and Futuna",
          "dial_code": "+681",
          "code": "WF"
        },
        {
          "name": "Yemen",
          "dial_code": "+967",
          "code": "YE"
        },
        {
          "name": "Zambia",
          "dial_code": "+260",
          "code": "ZM"
        },
        {
          "name": "Zimbabwe",
          "dial_code": "+263",
          "code": "ZW"
        }
      ]);
  }, []);


  // useEffect(() => {
  //   if (formValues.companyInfo.country) {
  //     const url = `https://api.first.org/data/v1/countries/${formValues.companyInfo.country.id}/states?limit=100&pretty=true`;
  //     axios.get(url)
  //       .then(response => {
  //         setstates(response.data.states);
  //         console.log(response.data.states);
  //       })
  //       .catch(error => {
  //         console.log('error fetching data ', error);
  //         setstates([
  //           { id: 1, name: 'Karnataka', cities: [] },
  //           { id: 2, name: 'Punjab', cities: [] },
  //         ]);
  //       });
  //   }
  // }, [formValues.companyInfo.country]);

  // useEffect(() => {
  //   if (formValues.companyInfo.state ) {hand
  //     const url = `https://api.first.org/data/v1/states/${formValues.companyInfo.state.id}/cities?limit=100&pretty=true`;
  //     axios.get(url)
  //       .then(response => {
  //         setcities(response.data.cities);
  //         console.log(response.data.cities);
  //       })
  //       .catch(error => {
  //         console.log('error fetching data ', error);
  //         setcities([
  //           { id: 1, name: 'Bengaluru' },
  //           { id: 2, name: 'Mysore' },
  //           { id: 3, name: 'Amritsar' },
  //           { id: 4, name: 'Ludhiana' },
  //         ]);
  //       });
  //   }
  // }, [formValues.companyInfo.state]);

 
  const handleInputChange = (e) => {
    e.target.value = e.target.value.trim();
    const { name, value } = e.target;
    console.log(name, value)

      const [section, field] = name.split('.');
      setFormValues((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value,
        },
      }));
   
    console.log(formValues)
 
  };

  const handleCountryInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)

      const [section, field] = name.split('.');
      setFormValues((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value,
        },
      }));
   
    console.log(formValues);
    // if(formValues.companyInfo.country.length > 0){
    //   const selectedCountry = countries.find(country => country.name === formValues.companyInfo.country);
    //   console.log(selectedCountry)
    //   fetch('https://www.qa-aecsprm-api.honeywell.com/sit-tpur/api/ECO/GetAllStatesByCountryId?countryId=${selectedCountry.id}' )
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('data',data);
    //     setcountries(data.countryData);
    //   })
    //   .catch(error =>   alert(error));
    // }
 
  };


  const handleCompanyphoneInputChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    // if (input.length <= 10) {
    //   setFormValues(prevFormValues => ({ ...prevFormValues, cphonenum: input }));     
    // }
    if (input.length <= 15) {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        companyInfo: {
          ...prevFormValues.companyInfo,
          phoneNo: input
        }
      }));
    }
  }

  const handlephoneInputChange = (e) => {
    console.log('e.target.value',e.target.value)
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= 15) {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        userInfo: {
          ...prevFormValues.userInfo,
          phoneNo: input
        }
      }));
    }
  }

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = e.target.value.trim(); // trim the value
    if (emailRegex.test(value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setFormValues(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        email: value
      }
    }));
    setOtpVerifiedPopup(false);
  };



  const handlesponserEmailChange = (e) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = e.target.value.trim(); // trim the value
    if (emailRegex.test(value)) {
      setEmailSponsError(false);
    } else {
      setEmailSponsError(true);
    }
    setFormValues(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        busiSponserEmail: value,
        busiSponserName: '',
      }
    }));
    
    setBussinesSponserEmailVerified(false);
    console.log('businessSponsorInitialValue',businessSponsorInitialValue)
    console.log('formValues.userInfo.busiSponserEmail',formValues.userInfo.busiSponserEmail)
   
  };

  const handleChange = (event) =>{
    setSubmit(event.target.checked)
  }

  const navigate = useNavigate();


  const handleSubmit = (e) => {
    setLoading(true);
    setIsLoading(true);
    e.preventDefault();
    console.log(formValues);
    setErrorWarning(false);
    console.log('otpVerified',otpVerified)
    console.log('bussinesSponserEmailVerified',bussinesSponserEmailVerified)
    if(otpVerified != false &&  bussinesSponserEmailVerified != false ){
      console.log('exists')
    if(formValues.scitizenship != undefined && formValues.scitizenship != null){
      if(Object.keys(formValues.scitizenship).length != 0 || Object.keys(formValues.pcitizenship).length != 0){
        if( typeof(formValues.scitizenship) != 'string'){
          formValues.scitizenship = '';
          formValues.pcitizenship = '';
        } 
       
      }
    }
 
    const params = {
      EntityInfo: {
        EntityName: formValues.companyInfo.companyName,
        EntityDUNSNumber: "",
        EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
        EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
        EntityCity: formValues.companyInfo.city,
        EntityState: formValues.companyInfo.state,
        EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
        EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
        EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
        EntityZip: formValues.companyInfo.postalCode
      },
      SubjectInfo: {
        EmailAddress: formValues.userInfo.email,
        BusinessSponsorEmail: formValues.userInfo.busiSponserEmail,
        BusinessSponsorName: formValues.userInfo.busiSponserName,
        Company: '',
        FirstName: formValues.userInfo.firstName,
        MiddleName: "",
        LastName: formValues.userInfo.lastName,
        Title_Position: "",
        Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
        PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
        PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
        PrimaryCitizenship:  formValues.pcitizenship,
        SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
        GreenCardHolder: formValues.userInfo.greenCardHolder,
        RegistrationStatus: "2"
      }
    }
    // const config ={
    //   headers: { 'Authorization' : `Bearer 4DctvIGXRZe77odVvAhSvGv7R3Rk`}
    // }
 const url = data[0].registrationUrl;
    axios.post(url,params)

      .then(response => {
        console.log('response',response);
        console.log('response',response.data);
        if(response.data.status == "success"){
          if(response.data.message == ""){
            navigate("/ConfirmationMessage",{state:{ dynamicMessage: false, dynamicData:"" }});
            // setSuccessPopup(true);
            setErrorrPopup(false);
            setSuccessDynamicPopup(false);
            setLoading(false);
            setIsLoading(false);
          } 
          else {
            // setSuccessDynamicPopup(true);
            setSuccessMsg(response.data.message);
            navigate("/ConfirmationMessage",{state:{ dynamicMessage: true, dynamicData:response.data.message }});

            setSuccessPopup(false);
            setErrorrPopup(false);
            setLoading(false);
            setIsLoading(false);

          }
        } else {
          setErrorMsg(response.data.message);
            setErrorrPopup(true);
            setSuccessDynamicPopup(false);
            setSuccessPopup(false)
            setLoading(false);
            setIsLoading(false);

        }

        // message is empty then show statc mesg.

        // message is not empty show dynamic msg

        
      })
      .catch(error => {
        alert(error)
        setLoading(false);
        setIsLoading(false);
    });
    // APi integration show popup  for success need to set the true
    // setSuccessPopup(true);
// APi integration show popup  for error need to set the true

  } else {
    setErrorWarning(true);
    let message = <>
    Please verify the <strong>Business Email</strong> and <strong>Business Sponsor Email</strong>.
    
  </>;
    if (!otpVerified && bussinesSponserEmailVerified) {  
        message = <>
        Please verify the <strong>Business Email</strong>.
        
      </>}
    else if (!bussinesSponserEmailVerified && otpVerified) {
        message = <>
        Please verify the <strong>Business Sponsor Email</strong>.
        
      </>;
      }

      setErrorMsg(message);
      setErrorrPopup(true);
      setLoading(false);
      setIsLoading(false);
  }
  };

  const handleSucessClose =  (event, reason) => {
    if (reason !== 'backdropClick') {
      setSuccessPopup(false);
    } 
  };
  const handleErrorClose =  (event, reason) => {
    if (reason !== 'backdropClick') {
      setErrorrPopup(false);

    }
  };
  const handleOtpClose =  (event, reason) => {
    if (reason !== 'backdropClick') {
      setOtpSuccessPopup(false);

      // testing
      // setOtpVerifiedPopup(true);
    } 
  };

  const cancel = (e) => {
    navigate("/");


  };
  const handleVerifyOtp = (event, reason)=>{
    console.log('otp',otp+'#'+formValues.userInfo.email);
    setLoading(true);
    setErrorWarning(false);
    if(formValues.scitizenship != undefined && formValues.scitizenship != null){
      if(Object.keys(formValues.scitizenship).length != 0|| Object.keys(formValues.pcitizenship).length != 0){
        if( typeof(formValues.scitizenship) != 'string'){
          formValues.scitizenship = '';
          formValues.pcitizenship = '';
        } 
       
      }
    }
    if (reason !== 'backdropClick') {
      const params = {
        EntityInfo: {
          EntityName: formValues.companyInfo.companyName ? formValues.companyInfo.companyName: '',
          EntityDUNSNumber: "",
          EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
          EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
          EntityCity: formValues.companyInfo.city ? formValues.companyInfo.city: '',
          EntityState: formValues.companyInfo.state ? formValues.companyInfo.state : '',
          EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
          EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
          EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
          EntityZip: formValues.companyInfo.postalCode ? formValues.companyInfo.postalCode:''
        },
        SubjectInfo: {
          EmailAddress: formValues.userInfo.email ? formValues.userInfo.email: '',
          BusinessSponsorEmail: formValues.userInfo.busiSponserEmail ? formValues.userInfo.busiSponserEmail: '',
          BusinessSponsorName: formValues.userInfo.busiSponserName ? formValues.userInfo.busiSponserName:'',
          Company: '',
          FirstName: formValues.userInfo.firstName ? formValues.userInfo.firstName:'',
          MiddleName: "",
          LastName: formValues.userInfo.lastName? formValues.userInfo.lastName:'',
          Title_Position: "",
          Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
          PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
          PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
          PrimaryCitizenship:  formValues.pcitizenship ?  formValues.pcitizenship:'',
          SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
          GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder:'',
          RegistrationStatus: 1+'#'+otp
        }
      }
      setVerifiedLdapUser(false);
   const url = data[0].registrationUrl;
      axios.post(url,params)
  
        .then(response => {
          console.log('response',response);
          console.log('response',response.data);
          setErrorMsg('');
          if(response.data.status == "success"){
           if(response.data.message.includes('verifiedOTP') && !response.data.message.includes('#')){
            setOtpSuccessPopup(false);
            setOtpVerifiedPopup(true);
            setLoading(false);
           }
           if(response.data.message.includes('verifiedOTP') && response.data.message.includes('#')){
              let message = response.data.message.split('#');
              console.log('message',message);
              setErrorMsg(response.data.message);
              setErrorrPopup(true);
              setOtpSuccessPopup(false);
              setOtpVerifiedPopup(true);
              setLoading(false);
              setErrorWarning(true)
              setErrorMsg(message[3]);
              setFormValues(prevState => ({
                ...prevState,
                userInfo: {
                  ...prevState.userInfo,
                  firstName: message[1],
                  lastName: message[2],
                }
              }));
              setVerifiedLdapUser(true);
        }
          } else {
            setErrorMsg(response.data.message);
            setOtpVerifiedPopup(false);
              setErrorrOtpPopup(true);
              setSuccessDynamicPopup(false);
              setSuccessPopup(false)
              setLoading(false);

          }
  
         
          
        })
        .catch(error => {
          alert(error)
          setLoading(false);

      });
    } 
  }
  const handleOtpOpen=  (event, reason) => {
    console.log('new changes version update');
      setResendTimer(10);
      setOtp("");
      setLoading(true);
      setErrorWarning(false);
      if(formValues.scitizenship != undefined && formValues.scitizenship != null){
      if(Object.keys(formValues.scitizenship).length != 0|| Object.keys(formValues.pcitizenship).length != 0){
        if( typeof(formValues.scitizenship) != 'string'){
          formValues.scitizenship = '';
          formValues.pcitizenship = '';
        } 
       
      }
    }
      const params = {
        EntityInfo: {
          EntityName: formValues.companyInfo.companyName ? formValues.companyInfo.companyName: '',
          EntityDUNSNumber: "",
          EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
          EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
          EntityCity: formValues.companyInfo.city ? formValues.companyInfo.city: '',
          EntityState: formValues.companyInfo.state ? formValues.companyInfo.state : '',
          EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
          EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
          EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
          EntityZip: formValues.companyInfo.postalCode ? formValues.companyInfo.postalCode:''
        },
        SubjectInfo: {
          EmailAddress: formValues.userInfo.email ? formValues.userInfo.email: '',
          BusinessSponsorEmail: formValues.userInfo.busiSponserEmail ? formValues.userInfo.busiSponserEmail: '',
          BusinessSponsorName: formValues.userInfo.busiSponserName ? formValues.userInfo.busiSponserName:'',
          Company: '',
          FirstName: formValues.userInfo.firstName ? formValues.userInfo.firstName:'',
          MiddleName: "",
          LastName: formValues.userInfo.lastName? formValues.userInfo.lastName:'',
          Title_Position: "",
          Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
          PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
          PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
          PrimaryCitizenship:  formValues.pcitizenship ?  formValues.pcitizenship:'',
          SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
          GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder:'',
          RegistrationStatus: "0"
        }
      }
    
   const url = data[0].registrationUrl;
      axios.post(url,params)
  
        .then(response => {
          console.log('response',response);
          console.log('response',response.data);
          setLoading(false);
          if(response.data.status == "success"){
           if(response.data.message == 'sentOTP'){
            //dialog open
            setOtpSuccessPopup(true);
           }
          } else {
            setErrorMsg(response.data.message);
              setErrorrPopup(true);
              setSuccessDynamicPopup(false);
              setSuccessPopup(false)
          }
  
         
          
        })
        .catch(error => {
          setLoading(false);
          alert(error)
      });
      // setOtpVerifiedPopup(true);
  };

  const handleBusiSponserEmailClick=  (event, reason) => {
      setBusinessLoading(true);
      setBussinesSponserEmailVerified(false);
      setErrorWarning(false);
      if(formValues.scitizenship != undefined && formValues.scitizenship != null){
      if(Object.keys(formValues.scitizenship).length != 0|| Object.keys(formValues.pcitizenship).length != 0){
        if( typeof(formValues.scitizenship) != 'string'){
          formValues.scitizenship = '';
          formValues.pcitizenship = '';
        } 
       
      }
    }
      const params = {
        EntityInfo: {
          EntityName: formValues.companyInfo.companyName ? formValues.companyInfo.companyName: '',
          EntityDUNSNumber: "",
          EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
          EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
          EntityCity: formValues.companyInfo.city ? formValues.companyInfo.city: '',
          EntityState: formValues.companyInfo.state ? formValues.companyInfo.state : '',
          EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
          EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
          EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
          EntityZip: formValues.companyInfo.postalCode ? formValues.companyInfo.postalCode:''
        },
        SubjectInfo: {
          EmailAddress: formValues.userInfo.email ? formValues.userInfo.email: '',
          BusinessSponsorEmail: formValues.userInfo.busiSponserEmail ? formValues.userInfo.busiSponserEmail: '',
          BusinessSponsorName: formValues.userInfo.busiSponserName ? formValues.userInfo.busiSponserName:'',
          Company: '',
          FirstName: formValues.userInfo.firstName ? formValues.userInfo.firstName:'',
          MiddleName: "",
          LastName: formValues.userInfo.lastName? formValues.userInfo.lastName:'',
          Title_Position: "",
          Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
          PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
          PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
          PrimaryCitizenship:  formValues.pcitizenship ?  formValues.pcitizenship:'',
          SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
          GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder:'',
          RegistrationStatus: "3"
        }
      }
      setBusinessSponsorInitialValue(formValues.userInfo.busiSponserEmail);
   
    
   const url = data[0].registrationUrl;
      axios.post(url,params)
  
        .then(response => {
          setBusinessLoading(false)
          if(response.data.status == "success"){
             if(response.data.message.length > 0){
              setFormValues(prevState => ({
                ...prevState,
                userInfo: {
                  ...prevState.userInfo,
                  busiSponserName: response.data.message,
                },
              }));
            setBusinessSponsorNameInitialValue(response.data.message);

             }
           

            setBussinesSponserEmailVerified(true);
            
          } else {
              setBussinesSponserEmailVerified(false);
              setErrorMsg(response.data.message);
              setErrorrPopup(true);
              setSuccessDynamicPopup(false);
              setSuccessPopup(false)
          }
  
         
          
        })
        .catch(error => {
          setBusinessLoading(false)
          setBussinesSponserEmailVerified(false);
          alert(error)
      });
      // setOtpVerifiedPopup(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));


  const handleSucessDynamicClose =  (event, reason) => {
    if (reason !== 'backdropClick') {
      setSuccessDynamicPopup(false);
    } 
  };

  
  
  return (
    <form onSubmit={handleSubmit} autocomplete="off">

<Container>
   <Grid container>
    <Grid item xs={12} md={12} sm={12} display="flex" justifyContent="flex-start">
    <img src={Logoimg} alt="Honeywell Logo" width="15%"/>
   </Grid>
    <Grid item xs={12} md={12} sm={12}>
    <Grid item xs={12} md={12} sm={12}>
        <Grid item xs={12} className="text_content" style={{marginBottom:'2rem'}}>
          <h1 className="main_heading">Account Registration</h1>
          <p>The below information is required in order to create an account for Honeywell's Technical Data Portal.</p>
          <p>Please note that Honeywell will process your personal information in accordance with Honeywell's <a href={require("./Pdfs/Global Contigent Worker Privacy Notice.pdf")} rel="noopener noreferrer" target='_blank'>Global Contingent Privacy Notice</a></p>
          <p>Providing information is optional, however without this information, Honeywell is unable to provide information subject to U.S. export control laws and regulations to you.</p>
        </Grid>
        {/* Supplier/External Member Information  */}
        <Grid item xs={12} className="main_content">
          <h3>External Member Information</h3>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FormLabel>Business Email<span className="required_color">*</span></FormLabel>
                <TextField
                  name="userInfo.email"
                  placeholder="Business Email"
                  value={formValues.userInfo.email}
                  onChange={handleEmailChange}
                  required
                  fullWidth
                  error={emailError}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton  color="primary">
                        {emailError == false  &&  formValues.userInfo.email.length != 0 && otpVerified == false &&   <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px', marginLeft:'1rem'}}  onClick={handleOtpOpen}>
                  {loading ? <>Loading..</> : <>VERIFY</>}
                </Button> }
                {emailError == false  &&  formValues.userInfo.email.length != 0 && otpVerified == true &&   <Button variant="contained" size="medium" color="success" style={{minWidth: '91px', marginLeft:'1rem'}} >
                  VERIFIED
                </Button> }
                        </IconButton>
                        
                      </InputAdornment>
                    ),
                  }}
                />
                {emailError && <FormHelperText>Please enter a valid email address</FormHelperText>}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Phone</FormLabel>
                <Grid container>
                  <Grid item xs={5}>
                    <Autocomplete
                      className="country_code_width"
                      id="country-search"
                      options={phoneNumber}
                      
                      getOptionLabel={(option) =>  option.name + '  ('+ option.dial_code+ ')'}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="30"
                            srcSet={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.code}.svg 2x`}
                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.code}.svg`}
                            alt=""
                          />
                          {option.name} ({option.dial_code})
                        </Box>
                      )}
                      value={phoneNumber.find((option) => option.dial_code === formValues.userInfo.phoneCode) || null}
                      onChange={(event, newValue) => {
                        if(newValue != null){
                          setFormValues({ ...formValues, userInfo: { ...formValues.userInfo, phoneCode: newValue.dial_code } });
                        }

                      }} 
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="userInfo.phoneCode"
                          value={formValues.userInfo.phoneCode}
                          placeholder="Select Country Code"
                          onChange={handleInputChange}
                          fullWidth
                        />
                      )}
                    />

                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      name="userInfo.phoneNo"
                      placeholder="Phone Number"
                      type="tel"
                      inputProps={{maxLength :15,autoComplete: 'new-password'}}
                      value={formValues.userInfo.phoneNo}
                      onChange={handlephoneInputChange}
                      
                      fullWidth
                    />
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>First Name<span className="required_color">*</span></FormLabel>
                <TextField
                  name="userInfo.firstName"
                  placeholder="First Name"
                  value={formValues.userInfo.firstName}
                  onChange={handleInputChange}
                  inputProps={{maxLength :100,autoComplete: 'new-password'}}
                  required
                  disabled={verifiedLdapUser}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Last Name<span className="required_color">*</span></FormLabel>
                <TextField
                  name="userInfo.lastName"
                  placeholder="Last Name"
                  value={formValues.userInfo.lastName}
                  inputProps={{maxLength :100,autoComplete: 'new-password'}}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[0-9 a-z A-Z (!@#%&?;:`~.,_+|'"-\/\r\n$₹€^*)]*$/.test(inputValue)) {
                      handleInputChange(e);
                    }
                  }}
                  disabled={verifiedLdapUser}
                  required
                  fullWidth
                />
              </Grid>
             
              
            


              <Grid item xs={12} md={6}>
                <FormLabel>Primary Citizenship<span className="required_color">*</span></FormLabel>
                <Autocomplete
                  id="country-search"
                  options={countries}
                  autoComplete='off'
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="30"
                        srcSet={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg 2x`}
                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg`}
                        alt=""
                      />
                      {option.name}
                    </Box>
                  )} 
                  onChange={(event, newValue) => {
                    setFormValues({ ...formValues, pcitizenship: newValue ? newValue.name: '' });
                    console.log('formValues.userInfo.secondaryCZ',newValue)
                    console.log(formValues.scitizenship);
                    if(newValue != null){
                      if(newValue.name == 'United States' || formValues.scitizenship == 'United States'){
                        setDisplayGreenCard(true);
                        setDisplayInfo(false)}
                        else {
                          setDisplayGreenCard(false);
                          setDisplayInfo(true);
                        }
                      }
                   
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="pcitizenship"
                      placeholder="Select Primary Citizenship"
                      value={formValues.pcitizenship}
                      onChange={(event) => {
                        const data = countries.filter((country) => country.name.toLowerCase().startsWith(event.target.value.toLowerCase()));
                          setFormValues(prevState => ({ ...prevState, pcitizenship: event.target.value ? data[0]?.name :'' }));
                      }}
                      onKeyDown={(event) => {
                        if(event.key == 'Backspace'){
                          setFormValues(prevState => ({ ...prevState, pcitizenship: '' }));
                        }
                      }}
                      onKeyUp={(event) => {
                        console.log('formValues.scitizenship',formValues);
                       
                       if(event.key == 'Backspace' &&  formValues.scitizenship == null){
                        setDisplayInfo(false)
                       }
                       if(formValues.pcitizenship == null  &&  formValues.scitizenship == null){
                        setDisplayInfo(false)
                       }
                       if(formValues.pcitizenship.undefined ==''  &&  formValues.scitizenship.undefined == '' ){
                        setDisplayInfo(false)
                       }
                       if(formValues.pcitizenship == '' && formValues.scitizenship == ''){
                        setDisplayGreenCard(true);
                       }
                    }}
                    autoComplete='new-password'
                      required
                      fullWidth
                      
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Secondary Citizenship</FormLabel>
                <Autocomplete
                  id="Secondary-Citizenship-search"
                  options={countries}
                  autoComplete='off'
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="30"
                        srcSet={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg 2x`}
                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg`}
                        alt=""
                      />
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    setFormValues({ ...formValues, scitizenship: newValue ? newValue.name: '' });
                    console.log('values', formValues.pcitizenship)
                    console.log('newValue',newValue);
                    if(newValue != null){
                      if(newValue.name == 'United States' || formValues.pcitizenship == 'United States'){
                        setDisplayGreenCard(true);
                        setDisplayInfo(false)}
                        else {
                          setDisplayGreenCard(false)
                          setDisplayInfo(true)
                        }
                    }
                    
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="scitizenship"
                      placeholder="Select Secondary Citizenship"
                      value={formValues.scitizenship}
                      autoComplete='new-password'
                      onChange={(event) => {
                        const data = countries.filter((country) => country.name.toLowerCase().startsWith(event.target.value.toLowerCase()));

                          setFormValues(prevState => ({ ...prevState, scitizenship: event.target.value ? data[0]?.name: '' }));
                      }}
                      onKeyDown={(event) => {
                        if(event.key == 'Backspace'){
                          setFormValues(prevState => ({ ...prevState, scitizenship: '' }));
                        }
                      }}
                      onKeyUp={(event) => {
                        if(event.key == 'Backspace' &&  formValues.pcitizenship != 'United States'){
                         setDisplayInfo(false)
                        }
                        if(formValues.pcitizenship == null  &&  formValues.scitizenship == null){
                          setDisplayInfo(false)
                         }
                         if(formValues.pcitizenship != 'United States'){
                          setDisplayInfo(true)
                         }
                         if(formValues.pcitizenship == '' && formValues.scitizenship == ''){
                          setDisplayGreenCard(true);
                         }
                     }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
          <FormLabel>Honeywell Contact(Preferred CM)<span className="required_color">*</span> </FormLabel>
            <TextField
              name="userInfo.honContact"
              placeholder="Enter Name/Email"
              value={formValues.userInfo.honContact}
              onChange={handleInputChange}
              required
              fullWidth
            />
          </Grid> */}
              {!displayGreenCard && displayInfo && <Grid item xs={12} md={6}>
                <FormLabel style={{display:'flex', alignItems:'center'}}>US Person Status (non-Citizen) <span className="required_color">*</span>
                <LightTooltip arrow title="Because you have indicated you do not have citizenship in the United States, please indicate if you are a U.S. green card holder or hold protected status to qualify as a “U.S. Person” under 22 CFR 120.62.  Please note that U.S. visa holders do not qualify as “U.S. Persons” under 22 CFR 120.62." style={{marginLeft:'0.5rem', cursor:'pointer'}}>
                <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9ZM7.86805e-07 9C1.22134e-06 4.02944 4.02944 -1.22135e-06 9 -7.86805e-07C13.9706 -3.52265e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 3.52264e-07 13.9706 7.86805e-07 9ZM8 13C8 13.5523 8.44772 14 9 14C9.55229 14 10 13.5523 10 13L10 9C10 8.44772 9.55229 8 9 8C8.44772 8 8 8.44771 8 9L8 13ZM8 6C8 6.55228 8.44772 7 9 7C9.55229 7 10 6.55228 10 6L10 5.5C10 4.94771 9.55229 4.5 9 4.5C8.44772 4.5 8 4.94771 8 5.5L8 6Z" fill="#707070"/>
</svg>

      </LightTooltip></FormLabel>
                <Select
                  displayEmpty
                  name="userInfo.greenCardHolder"
                  value={formValues.userInfo.greenCardHolder}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  inputProps={{ autoComplete: 'new-password' }}
                >  
                <MenuItem value="" disabled>
                   <span style={{color:"#9E9E9E"}}> Select US Person Status</span>
        </MenuItem>
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </Grid> }
             
              {!displayGreenCard && displayInfo &&<Grid item xs={12} md={12} style={{marginBottom:'1.5rem'}}>
                <div  style={{border: '1px solid #1792E5', borderRadius: '4px', display:'flex',padding: '.5rem'}}>
                <div >
              <img src={Info} alt="Honeywell Logo" style={{height: '26px',marginBottom: '6px'}}/>
            </div>
            <div  style={{color: '#707070', fontSize:'14px',lineHeight:'20px',marginLeft: '.5rem', fontStyle:'italic'}}>
              <span > Because you have indicated you do not have citizenship in the United States, please indicate if you are a U.S. green card holder or hold protected status to qualify as a “U.S. Person” under 22 CFR 120.62.  Please note that U.S. visa holders do not qualify as “U.S. Persons” under 22 CFR 120.62. </span>
            </div>
                </div>
                
               
                </Grid>}
                <Grid item xs={12} md={6}>
                <FormLabel style={{display:'flex',alignItems:'center'}}>Business Sponsor Email<span className="required_color">*</span>
                <LightTooltip arrow title="Business Sponsor is a Honeywell business contact" style={{marginLeft:'0.5rem', cursor:'pointer'}}>
                <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9ZM7.86805e-07 9C1.22134e-06 4.02944 4.02944 -1.22135e-06 9 -7.86805e-07C13.9706 -3.52265e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 3.52264e-07 13.9706 7.86805e-07 9ZM8 13C8 13.5523 8.44772 14 9 14C9.55229 14 10 13.5523 10 13L10 9C10 8.44772 9.55229 8 9 8C8.44772 8 8 8.44771 8 9L8 13ZM8 6C8 6.55228 8.44772 7 9 7C9.55229 7 10 6.55228 10 6L10 5.5C10 4.94771 9.55229 4.5 9 4.5C8.44772 4.5 8 4.94771 8 5.5L8 6Z" fill="#707070"/>
</svg>

      </LightTooltip></FormLabel>
                <TextField
                  name="userInfo.busiSponserEmail"
                  placeholder="Business Sponsor Email"
                  value={formValues.userInfo.busiSponserEmail}
                  onChange={(event) => {
                    handlesponserEmailChange(event);
                    console.log(businessSponsorNameInitialValue); // log the current value to the console
                    if(businessSponsorInitialValue == event.target.value){
                      setBussinesSponserEmailVerified(true);
                      
                      setFormValues(prevState => ({
                        ...prevState,
                        userInfo: {
                          ...prevState.userInfo,
                          busiSponserName: businessSponsorNameInitialValue,
                        }
                      }));
                        }
                  }}
                  required
                  fullWidth
                 
                  error={emailSponsError}
                  InputProps={{
                    autoComplete: 'new-password',
                    maxLength :100,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton  color="primary">
                        {emailSponsError == false  &&  formValues.userInfo.busiSponserEmail.length != 0 && bussinesSponserEmailVerified == false &&   <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px', marginLeft:'1rem'}}  onClick={handleBusiSponserEmailClick}>
                  {businessloading ? <>Loading..</> : <>VERIFY</>}
                </Button> }
                {emailSponsError == false  &&  formValues.userInfo.busiSponserEmail.length != 0 && bussinesSponserEmailVerified == true &&   <Button variant="contained" size="medium" color="success" style={{minWidth: '91px', marginLeft:'1rem'}} >
                  VERIFIED
                </Button> }
                        </IconButton>
                        
                      </InputAdornment>
                    ),
                  }}
                />
                {emailSponsError && <FormHelperText>Please enter a valid email address</FormHelperText>}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel style={{display:'flex',alignItems:'center'}}>Business Sponsor Name
                {/* <LightTooltip arrow title="Business Sponsor is a Honeywell business contact" style={{marginLeft:'0.5rem', cursor:'pointer'}}>
                <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9ZM7.86805e-07 9C1.22134e-06 4.02944 4.02944 -1.22135e-06 9 -7.86805e-07C13.9706 -3.52265e-07 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 3.52264e-07 13.9706 7.86805e-07 9ZM8 13C8 13.5523 8.44772 14 9 14C9.55229 14 10 13.5523 10 13L10 9C10 8.44772 9.55229 8 9 8C8.44772 8 8 8.44771 8 9L8 13ZM8 6C8 6.55228 8.44772 7 9 7C9.55229 7 10 6.55228 10 6L10 5.5C10 4.94771 9.55229 4.5 9 4.5C8.44772 4.5 8 4.94771 8 5.5L8 6Z" fill="#707070"/>
</svg>

      </LightTooltip> */}
      </FormLabel>
                <TextField
                  name="userInfo.busiSponserName"
                  placeholder="Business Sponsor Name"
                  inputProps={{maxLength :100,autoComplete: 'off'}}
                  value={formValues.userInfo.busiSponserName}
                  //  onChange={handleInputChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[0-9 a-z A-Z (!@#%&?;:`~.,_+|'"-\/\r\n$₹€^*)]*$/.test(inputValue)) {
                      handleInputChange(e);
                    }
                  }}
                  fullWidth
                  disabled={true}
                />
               
             
                         
              </Grid>
            </Grid>
         
          </Grid>
        </Grid>
        {/* end */}
        {/* Company Information */}
        <Grid item xs={12} className="main_content_company" style={{marginTop:'3rem'}}>
          <h3>Company Information</h3>
          <Grid item xs={12} className="text_content" style={{marginBottom:'1rem'}}>
          {/* <p>Enter complete and accurate information about the legal entity of your employer.</p> */}
          <p style={{fontSize:'medium', fontWeight:'bold'}}>Important Note:</p>
          Accuracy of Legal Entity Information is Crucial. Please ensure that the legal entity name you provide exactly matches the official registered name of your employer.
           Any discrepancies may lead to account verification issues or delays. 
        </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormLabel>Company Name <span className="required_color">*</span></FormLabel>
                <TextField
                  name="companyInfo.companyName"
                  placeholder="Company Name"
                  value={formValues.companyInfo.companyName}
                  inputProps={{maxLength :100,autoComplete:'new-password'}}
                  onChange={handleInputChange}
                  required
                  autoComplete='new-password'
                  fullWidth
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormLabel>Company Address1 <span className="required_color">*</span></FormLabel>
                <TextField
                  name="companyInfo.address1"
                  placeholder="Company Address1"
                  value={formValues.companyInfo.address1}
                  inputProps={{maxLength :100,autoComplete:'new-password'}}
                  onChange={handleInputChange}
                  required
                  autoComplete='new-password'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Company Address2</FormLabel>
                <TextField
                  name="companyInfo.address2"
                  placeholder="Company Address2"
                  value={formValues.companyInfo.address2}
                  inputProps={{maxLength :100,autoComplete: 'new-password'}}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Company Address3</FormLabel>
                <TextField
                  name="companyInfo.address3"
                  placeholder="Company Address3"
                  inputProps={{maxLength :100,autoComplete: 'new-password'}}
                  value={formValues.companyInfo.address3}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Country <span className="required_color">*</span></FormLabel>
                <Autocomplete
                  id="country-search"
                  autoComplete='new-password'
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="30"
                        srcSet={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg 2x`}
                        src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.iso2}.svg`}
                        alt=""
                      />
                      {option.name}
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    setFormValues(prevState => ({
                      ...prevState,
                      companyInfo: {
                        ...prevState.companyInfo,
                        country: newValue ? newValue.name : '',
                        state: '', // set state to an empty string every time the country changes
                        city: '', // set city to an empty string every time the state changes
                      },
                    }));
                    setCities([]);
                    if (newValue) {
                      
    const params = {
      EntityInfo: {
        EntityName: formValues.companyInfo.companyName,
        EntityDUNSNumber: "",
        EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
        EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
        EntityCity: formValues.companyInfo.city,
        EntityState: formValues.companyInfo.state,
        EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
        EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
        EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
        EntityZip: formValues.companyInfo.postalCode
      },
      SubjectInfo: {
        EmailAddress: formValues.userInfo.email,
        BusinessSponsorEmail: formValues.userInfo.busiSponserEmail,
        BusinessSponsorName: formValues.userInfo.busiSponserName,
        Company: '',
        FirstName: formValues.userInfo.firstName,
        MiddleName: "",
        LastName: formValues.userInfo.lastName,
        Title_Position: "",
        Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
        PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
        PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
        PrimaryCitizenship:  formValues.pcitizenship ? formValues.pcitizenship: '',
        SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
        GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder :'',
        RegistrationStatus: 5+'#'+newValue.id
      }
    }
  
                    const url = data[0].registrationUrl;
                    axios.post(url,params)
                      .then(data => {
                        console.log(data.data);
                        setStates(data.data.stateData);
                      }
                    )
                      .catch((error) => {
                        console.error('Error:', error);
                      });
                    } else {
                      console.log('no option selected');
                      setStates([]);
                      setCities([]);
                      setFormValues(prevState => ({
                        ...prevState,
                        companyInfo: {
                          ...prevState.companyInfo,
                          state: '',
                          city: '',
                        },
                      }));

                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyInfo.country"
                      placeholder="Select Country"
                      value={formValues.companyInfo.country}
                      required
                      autoComplete='new-password'
                      fullWidth
                    />
                  )}
                />
              </Grid>
             
              <Grid item xs={12} md={6}>
                <FormLabel>State <span className="required_color">*</span></FormLabel>
                {/* <TextField
                  name="companyInfo.state"
                  placeholder="State"
                  value={formValues.companyInfo.state}
                  inputProps={{maxLength :100,autoComplete: 'new-password'}}
                  //  onChange={handleInputChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      handleInputChange(e);
                    }
                  }}
                  required
                  fullWidth
                /> */}
                 <Autocomplete
                  id="state-search"
                  autoComplete='new-password'
                  options={states}
                  getOptionLabel={(option) => option.name}
                  disabled={!states.length} 
                  value={formValues.companyInfo.state ? states.find(state => state.name === formValues.companyInfo.state) : null}
                  onChange={(event, newValue) => {
                    setFormValues(prevState => ({
                      ...prevState,
                      companyInfo: {
                        ...prevState.companyInfo,
                        state: newValue ? newValue.name : '',
                        city: '', // set city to an empty string every time the state changes
                      },
                    }));
                      if (newValue) {
                        const params = {
                          EntityInfo: {
                            EntityName: formValues.companyInfo.companyName,
                            EntityDUNSNumber: "",
                            EntityAddress1: formValues.companyInfo.address1 ? formValues.companyInfo.address1: '',
                            EntityAddress2: formValues.companyInfo.address2 ? formValues.companyInfo.address2: '',
                            EntityCity: formValues.companyInfo.city,
                            EntityState: formValues.companyInfo.state,
                            EntityCountry: formValues.companyInfo.country ? formValues.companyInfo.country: '',
                            EntityPhoneNumber: formValues.companyInfo.phoneNo ? formValues.companyInfo.phoneNo :'',
                            EntityPhoneCountryCode: formValues.companyInfo.phoneCode ? formValues.companyInfo.phoneCode: '',
                            EntityZip: formValues.companyInfo.postalCode
                          },
                          SubjectInfo: {
                            EmailAddress: formValues.userInfo.email,
                            BusinessSponsorEmail: formValues.userInfo.busiSponserEmail,
                            BusinessSponsorName: formValues.userInfo.busiSponserName,
                            Company: '',
                            FirstName: formValues.userInfo.firstName,
                            MiddleName: "",
                            LastName: formValues.userInfo.lastName,
                            Title_Position: "",
                            Country: formValues.companyInfo.country ?formValues.companyInfo.country :'',
                            PhoneNo: formValues.userInfo.phoneNo ? formValues.userInfo.phoneNo: '',
                            PhoneCountryCode: formValues.userInfo.phoneCode ? formValues.userInfo.phoneCode :'',
                            PrimaryCitizenship:  formValues.pcitizenship ? formValues.pcitizenship: '',
                            SecondaryCitizenship: formValues.scitizenship ? formValues.scitizenship : '',
                            GreenCardHolder: formValues.userInfo.greenCardHolder ? formValues.userInfo.greenCardHolder :'',
                            RegistrationStatus: 6+'#'+newValue.id
                          }
                        }
                      
                                        const url = data[0].registrationUrl;
                                        axios.post(url,params)
                      .then(data => {
                        console.log(data);
                        setCities(data.data.cityData);
                      })
                      .catch((error) => {
                        console.error('Error:', error);
                      });
                    } else {
                      console.log('no option selected');
                      setCities([]);
                      setFormValues(prevState => ({
                        ...prevState,
                        companyInfo: {
                          ...prevState.companyInfo,
                          state: '',
                          city: ''
                        },
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyInfo.state"
                      placeholder="Select State"
                      value={formValues.companyInfo.state}
                      required
                      autoComplete='new-password'
                      fullWidth
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormLabel>City <span className="required_color">*</span></FormLabel>
                {/* <TextField
                  name="companyInfo.city"
                  placeholder="City"
                  value={formValues.companyInfo.city}
                  inputProps={{maxLength :100,autoComplete:'new-password'}}
                  //  onChange={handleInputChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z][a-zA-Z\s]*$/.test(inputValue)) {
                      handleInputChange(e);
                    } 
                    if (inputValue.trim().length  == 0) {
                      setFormValues({ ...formValues, companyInfo: { ...formValues.companyInfo, city:'' } });
                    } 
                  }}
                  required
                  fullWidth
                /> */}
                  <Autocomplete
                  id="city-search"
                  autoComplete='new-password'
                  disabled={!cities.length}
                  value={formValues.companyInfo.city ? cities.find(city => city.name === formValues.companyInfo.city) : null}
                  options={cities}
                  getOptionLabel={(option) => option.name}
                 
                  onChange={(event, newValue) => {
                    setFormValues({ ...formValues, companyInfo: { ...formValues.companyInfo, city: newValue ? newValue.name: '' } });
                   
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyInfo.city"
                      placeholder="Select City"
                      value={formValues.companyInfo.city}
                      required
                      autoComplete='new-password'
                      fullWidth
                    />
                  )}
                />
                
              </Grid>
              
              
              <Grid item xs={12} md={6}>
                <FormLabel>Zip Postal Code<span className="required_color">*</span></FormLabel>
                <TextField
                  name="companyInfo.postalCode"
                  placeholder="zip code"
                  value={formValues.companyInfo.postalCode}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  
                  inputProps={{ maxLength :100 ,autoComplete: 'new-password'}}
                 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel>Company Phone Number</FormLabel>
                <Grid container>
                  <Grid item xs={5}>
                    
                    <Autocomplete
                      className="country_code_width"
                      id="country-search"
                      options={phoneNumber}
                      getOptionLabel={(option) =>  option.name + '  ('+ option.dial_code+ ')'}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="30"
                            srcSet={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.code}.svg 2x`}
                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${option.code}.svg`}
                            alt=""
                          />
                          {option.name} ({option.dial_code})
                        </Box>
                      )}
                      value={phoneNumber.find((option) => option.dial_code === formValues.companyInfo.phoneCode) || null}
                      onChange={(event, newValue) => {
                        setFormValues({ ...formValues, companyInfo: { ...formValues.companyInfo, phoneCode: newValue.dial_code } });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="phoneCode"
                          value={formValues.companyInfo.phoneCode}
                          onChange={handleInputChange}
                          placeholder="Select Country Code"
                          fullWidth
                          
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={7} className="Company_phone">
                    <TextField
                      name="companyInfo.phoneNo"
                      placeholder="Phone Number"
                      inputProps={{maxLength :15,autoComplete:'new-password'}}
                      value={formValues.companyInfo.phoneNo}
                      onChange={handleCompanyphoneInputChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12} md={6}>

              </Grid>
              {/* <Grid item xs={12} md={6}>
          <FormLabel>Password <span className="required_color">*</span></FormLabel>
            <TextField
              name="userInfo.password"
              type="password"
              placeholder="Password"
              value={formValues.userInfo.password}
              onChange={handleInputChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <FormLabel>Confirm Password <span className="required_color">*</span></FormLabel>
            <TextField
              name="userInfo.password"
              type="password"
              placeholder="Confirm Password"
              value={formValues.userInfo.password}
              onChange={handleInputChange}
              required
              fullWidth
            />
          </Grid>  */}
            </Grid>
          </Grid>
        </Grid>
        {/* end */}
        {/* terms and conditions block */}
        <Grid item xs={12} md={12} className="last_border">
          <Checkbox  onChange={handleChange}/>
          <FormLabel>By submitting this form, you consent to Honeywell’s<a href="https://www.honeywell.com/us/en/terms-and-conditions" rel="noopener noreferrer" target='_blank'> Terms & Conditions</a> and understand Honeywell will process your personal information in accordance with its<a href="https://www.honeywell.com/us/en/privacy-statement" rel="noopener noreferrer" target='_blank'> Privacy Policy.</a></FormLabel>

        </Grid>
        {/* end */}
        {/* buttons code */}
        <Grid item xs={12} md={12} className="buttons_style">
          <Button variant="contained" color="primary" type="reset" className="cancel_button" onClick={cancel}>
            CANCEL
          </Button>
          <Button variant="contained"  type="submit"  disabled={!submitFlag }>
            {loading ? <>SUBMITTING..</> : <>SUBMIT</>}
          </Button>
        

            <Backdrop open={isLoading} style={{ zIndex: 9999, color: '#fff',flexDirection: 'column'  }}>
            <CircularProgress color="inherit" />
            <Typography style={{ marginTop: '20px' }}>Submitting Request...</Typography>
            </Backdrop>
    
           <Dialog  open={successPopup} onClose={handleSucessClose}  disableEscapeKeyDown>
        <DialogTitle id="responsive-dialog-title" style={{maxWidth:'80vw', width:'460px', position:'static'}}>
        <Grid container spacing={2} alignItems="center" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Grid item>
                <img src={success}  />
                  {/* <CheckCircleOutlineIcon fontSize="large" sx={{ color: "green" }} /> */}
                </Grid>
                <Grid item style={{width: 'calc(100% - 65px)'}}>
                  <h4 style={{margin: '0px 0px 15px 0px'}}>Success</h4>
                  <p style={{margin:'0px', fontSize:'16px', color:'#606060'}}>Thank you for submitting the information. The details have been successfully received and sent for verification. We'll notify you once the verification process is complete. Thank you for your cooperation.</p>
                </Grid>
                <Grid item style={{position: 'absolute', right: '25px'}}>
                  <IconButton edge="end" color="inherit" onClick={handleSucessClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
         
          </DialogContentText>
        </DialogContent> */}
        <DialogActions style={{paddingBottom: '16px',paddingRight: '16px'}}>
        <Grid className="Approve_ok" style={{paddingTop: '0px'}}>
                <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px'}}  onClick={handleSucessClose}>
                  OK
                </Button>
              </Grid>
        </DialogActions>
      </Dialog>

      <Dialog  open={successDynamicPopup} onClose={handleSucessDynamicClose}  disableEscapeKeyDown>
        <DialogTitle id="responsive-dialog-title" style={{maxWidth:'80vw', width:'460px', position:'static'}}>
        <Grid container spacing={2} alignItems="center" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Grid item>
                <img src={success}  />
                  {/* <CheckCircleOutlineIcon fontSize="large" sx={{ color: "green" }} /> */}
                </Grid>
                <Grid item style={{width: 'calc(100% - 65px)'}}>
                  <h4 style={{margin: '0px 0px 15px 0px'}}>Success</h4>
                  <p style={{margin:'0px', fontSize:'16px', color:'#606060'}}>{successMsg}</p>
                </Grid>
                <Grid item style={{position: 'absolute', right: '25px'}}>
                  <IconButton edge="end" color="inherit" onClick={handleSucessDynamicClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
         
          </DialogContentText>
        </DialogContent> */}
        <DialogActions style={{paddingBottom: '16px',paddingRight: '16px'}}>
        <Grid className="Approve_ok" style={{paddingTop: '0px'}}>
                <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px'}}  onClick={handleSucessDynamicClose}>
                  OK
                </Button>
              </Grid>
        </DialogActions>
      </Dialog>

      <Dialog  open={errorrPopup} onClose={handleErrorClose} disableEscapeKeyDown>
        <DialogTitle id="responsive-dialog-title" style={{maxWidth:'80vw', width:'460px', position:'static'}}>
        <Grid container spacing={2} alignItems="center" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
       
          
            <Grid item>
            <img src={errror}  />
            </Grid>
         
            <Grid item style={{width: 'calc(100% - 65px)'}}>
            {!errorWarning && ( <h4 style={{margin: '0px 0px 15px 0px'}}>Error</h4>)}
            {errorWarning && ( <h4 style={{margin: '0px 0px 15px 0px'}}>Alert</h4>)}
              <p style={{margin:'0px', fontSize:'16px', color:'#606060'}}>{errorMsg}</p>
            </Grid>
  


                <Grid item style={{position: 'absolute', right: '25px'}}>
                  <IconButton edge="end" color="inherit" onClick={handleErrorClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
         
          </DialogContentText>
        </DialogContent> */}
        <DialogActions style={{paddingBottom: '16px',paddingRight: '16px'}}>
        <Grid className="Approve_ok" style={{paddingTop: '0px'}}>
                <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px'}}  onClick={handleErrorClose}>
                  OK
                </Button>
              </Grid>
        </DialogActions>
      </Dialog>
     

  

      <Dialog PaperProps={{
    sx: {
      width: "100%",
      maxWidth: "575px!important",
    },
  }}
        
        aria-labelledby="customized-dialog-title"
        open={otpSuccessPopup}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"  maxWidth='sm' style={{fontSize:'24px', fontWeight:'bold', textAlign:'center'}}>
        Email Verification
       
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOtpClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 15,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ padding: '40px 45px 40px 45px'}}>
          <Typography gutterBottom style={{margin:'0px',color:'#606060', fontSize:'19px'}}>
          An OTP has been sent to your Email address.
          </Typography>
         
         <Typography>
           <div style={{marginTop:'2rem',marginBottom:'1rem'}}>
                  <OTPInput
                   inputStyle={{
                    width: "48px",
                    height: "48px",
                    borderRadius: ".375rem",
                    border: "2px solid rgba(0,0,0,0.3)"
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={
                        <span
                          style={{
                            fontSize: "7px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          {" "}
                        </span>
                      }
                  renderInput={(props) => <input {...props} />}
      />
      
      {resendTimer != 0 &&   
        <p style={{fontSize:'16px', textAlign:'start', fontSize:'16px',fontWeight:'bold'}}>Haven't received the OTP?  {resendTimer}s</p>
        
        }
        {resendTimer == 0 &&   
        <p style={{fontSize:'16px', textAlign:'start', fontSize:'16px',fontWeight:'bold'}}>Haven't received the OTP?  <span style={{color: '#1976d2', cursor:'pointer'}} variant="text" size="small" color="primary"  
        onClick={handleResend}>
                   Resend
                 </span></p>
        
        }
     
    {errorrOTPPopup &&<p style={{color:'red'}}> {errorMsg}</p>}
    </div>
         </Typography>
        </DialogContent>
        <DialogActions style={{paddingBottom: '16px',paddingRight: '16px',marginTop:'7px'}}>
        <Button variant="outlined" size="medium" color="primary" style={{minWidth: '91px'}} 
       onClick={handleOtpClose}>
                  CANCEL
                </Button>
          <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px', marginLeft:'1rem'}}  onClick={handleVerifyOtp} disabled={otp.length != 6 }>
          {loading ? <>VERIFYING..</> : <>VERIFY</>}
                </Button>
        </DialogActions>
      </Dialog>
        </Grid>
        {/* end */}
      </Grid>
    </Grid>

   </Grid>
   </Container>
    

    </form>

  )
}

 
export default Registrationform;