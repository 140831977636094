import "./Footer.css";
import React from "react";
import privacyicon from './Images/ccpa-privacyoptions.svg';
import { Grid } from "@mui/material";

function Footer() {
  return(
    <div className="footer-container">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <div className="copyright_text">
          <ul>
            <li>Copyright © 2024 Honeywell International Inc. </li>
          </ul>
        </div>
        <div className="terms-and-conditions-text">
          <ul>
            <li><a href="https://www.honeywell.com/us/en/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
            <span className="footer_divider">|</span>
            <li><a href="https://www.honeywell.com/us/en/privacy-statement" target="_blank" rel="noopener noreferrer">Privacy Statement</a></li>
            <span className="footer_divider"> |</span>
            <li><a href="https://hon-opcenter.my.salesforce-sites.com/PrivacyInformationRequestForm?lang=en" target="_blank" rel="noopener noreferrer">Your Privacy Choices<img src={privacyicon} alt="chioces icon" width={"30px"}/></a>
            
            </li>
            <span className="footer_divider">|</span>
            <li><a href="https://www.honeywell.com/us/en/cookie-notice" target="_blank" rel="noopener noreferrer">Cookie Notice</a></li>
          </ul>
        </div>
      </Grid>
    </div>
  );
}

export default Footer;