import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import TDPLanding from './Components/TDPLanding';
import RegistrationForm from './Components/Registrationform';
import ConfirmationMessage from './Components/ConfirmationMessage';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<TDPLanding/>} />
          <Route path='/SupplierRegistration' element={<RegistrationForm/>} />
          <Route path='/ConfirmationMessage' element={<ConfirmationMessage/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
