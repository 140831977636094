import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Modal, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Video1 from "./videos/Video-01.mp4";
import Video2 from "./videos/Video-02.mp4";
import "./Videocomponent.css";
var link1 =
  "https://honeywellprod.sharepoint.com/sites/Stream/_layouts/15/stream.aspx?id=%2Fsites%2FStream%2FAECS%2FHow%20to%20share%20technical%20information%20using%20TDP%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview";
var link2 =
  "https://honeywellprod.sharepoint.com/%3ap%3a/r/sites/DWP-Functions/law/ExportCompliance/Documents/AECS/AECS End to End Job Aid.pptx?d=wb94b71b5d2354368813111b9be459540&csf=1&web=1&e=URrE1U&xsdata=MDV8MDJ8fDIwMGM1YTg5ZGI0ZDQzMzE2MzQzMDhkYzQ0NGNkMDc2fDk2ZWNlNTI2OWM3ZDQ4YjA4ZGFmOGI5M2M5MGE1ZDE4fDB8MHw2Mzg0NjAzNDM4ODUzOTA2NTl8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPamMzTlRGalpXUTBMVGswWVdRdE5HUTNOaTA1TVRsbUxUazFOVFF3WldGaE1HWTJObDlpTkdJNFpXWmpNUzA0TmpJNExUUTRNemt0WW1Rek5TMDFOakJsWkRneVlUUXhZakJBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN4TURRek56VTROelE0TXc9PXw2MmViZGFjN2UxYTc0ZmY2NjM0MzA4ZGM0NDRjZDA3NnxiZmRiYTkzN2UzMjA0ZmJiOGRlZTQzZDhlOTNlZjE2MQ%3D%3D&sdata=dmdWcUlaWGFtL29lT05KMm93eWxmQjN0bWdqY29nNHdId1prTG04Q3d5RT0%3D&ovuser=96ece526-9c7d-48b0-8daf-8b93c90a5d18%2CA.Ajay.Subramanian%40Honeywell.com";
const VideoModal = ({ open, handleClose, videoUrl, title }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
    >
      <div className="video_position">
        {/* style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }} */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" id="video-modal-title">
            {title}
          </Typography>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <video controls style={{ width: "100%" }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Modal>
  );
};
const Videocomponent = () => {
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const videos = {
    video1: { url: Video1, title: "Accessing Package Received From Honeywell" },
    video2: { url: Video2, title: "Sending a new TDP to Honeywell" },
  };
  const handleOpen = (video) => {
    setVideoUrl(videos[video].url);
    setVideoTitle(videos[video].title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setVideoUrl(""); // Reset the video URL when closing the modal
    setVideoTitle(""); // Reset the video title when closing the modal
  };
  return (
    <Grid xs={12} md={12} sm={12} spacing={2} className="main_border_video">
      {/* <Grid xs={12} md={12} sm={12} className="Honeywell_users_Grid">
<span className="Honeywell_heading">Honeywell Users –</span>   
<div  className="Honeywell_users">  
    <span>Watch the video <a href={link1} target="_blank" rel="noopener noreferrer">Sharing technical Information using TDP</a> </span><br/>
    <span> Review the updated <a href={link2} target="_blank" rel="noopener noreferrer">AECS End to End Job Aide</a> for user detail.</span>
</div>
</Grid> */}
      <Grid xs={12} md={12} sm={12} className="External_users_Gird">
        <span className="External_heading">Supplier / Customer – </span>
        <div className="External_users">
          <span>
            <a
              href={require("./Pdfs/TDP_account_process.pdf")}
              rel="noopener noreferrer"
              target="_blank" className="new_acc_crp"
            >
              New Account Creation Process              
            </a>
          </span>
          <div>
            <span>Watch the videos</span>
          </div>
          <span>
            {" "}
            <a href="#" onClick={() => handleOpen("video1")}>
              Accessing Package Received From Honeywell
            </a>
          </span>
          <br />
          <span>
            {" "}
            <a href="#" onClick={() => handleOpen("video2")}>
              Sending a new TDP to Honeywell
            </a>
          </span>
          <VideoModal
            open={open}
            handleClose={handleClose}
            videoUrl={videoUrl}
            title={videoTitle}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Videocomponent;
