import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import "./Login.css";

const openEmailClient = (email) => {
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
      {/* AEROTDPSupport@honeywell.com  */}
      AeroAECSSupport@Honeywell.com
    </a>
  );
};
const defaultEmail = "AeroAECSSupport@Honeywell.com";
const emailLink = openEmailClient(defaultEmail);
const Login = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        setData(myjson);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Grid xs={12} md={12} sm={12} className="login_top">
      <Grid xs={12} md={12} sm={12} className="Main-heading">
        {data &&
          data.length > 0 &&
          data.map((val) => {
            return <span key={val.id}>{val.portal} Technical Data Portal</span>;
          })}
      </Grid>
      <Grid xs={12} md={12} sm={12} className="tdp-button">
        {data &&
          data.length > 0 &&
          data.map((val) => {
            return (
              <a href={val.loginurl}>
                <Button className="Login-button">LOGIN</Button>
              </a>
            );
          })}
      </Grid>
      <Grid xs={12} md={12} sm={12} className="Training-content">
        <Grid container>
          <Grid item md={8} xs={12} sm={12}>
             <span>
                    <Link
                      to="/SupplierRegistration" 
                    >
                      New External Member Account Registration
                    </Link>{" "}
                    {/* <Link to={{pathname:"/SupplierRegistration"}}
                       target="_blank" rel="noopener noreferrer"
                    >
                      New Supplier Account Registration - Testing
                    </Link> */}
                  </span>
          </Grid>
          <Grid item md={4} xs={12} sm={12} className="TDP_Training">
            <span>
              <a
                href={require("./Pdfs/TDP Trainings.pdf")}
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                TDP Trainings
              </a>
            </span>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} sm={12}>
          <div className="technical-mail">
            <span>For Technical Assistance, Contact: {emailLink}</span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
