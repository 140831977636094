import React from "react";
import { useState,useEffect } from "react";
import './ConfirmationMessage.css';
import { Grid ,Container, Button} from "@mui/material";
import success from "./Images/success.svg";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



const ConfirmationMessage=()=>{
  const [data, setData] = useState([]);
  const registrationFormData  = useLocation();

  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        console.log(myjson);
        setData(myjson);
      });
  };
  const navigateToHomePage = (e) => {
    navigate("/");


  };

  const navigate = useNavigate();

  useEffect(() => {
    console.log('registrationFormData',JSON.stringify(registrationFormData))
    getData();
  }, []);
    return(
            <div style={{marginTop:'20px'}}>
              {registrationFormData.state.dynamicMessage == false > 0 &&
 <Grid container
 spacing={2}
 direction="column"
 alignItems="center"
 justifyContent="center"
 marginTop={'0px'}
 sx={{ minHeight: '50vh' }}>
    <img style={{marginBottom:'1rem',width:'60px'}} src={success}  />
    <p style={{margin:'0px', fontSize:'22px', color:'#606060',lineHeight:'30px',fontWeight: 700, marginBottom:'2rem'}}>Details submitted successfully</p>
    <p style={{margin:'0px', fontSize:'22px',lineHeight:'33px',color:'#606060'}}>The details have been successfully received and sent for verification.</p>
    <p style={{margin:'0px', fontSize:'22px',lineHeight:'33px',color:'#606060'}}>We'll notify you once the verification process is complete.</p>
    <p style={{margin:'0px', fontSize:'22px',lineHeight:'33px',color:'#606060'}}>Thank you for your cooperation.</p>
    <Grid className="Approve_ok" style={{paddingTop: '25px'}}>
                <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px'}}   onClick={navigateToHomePage}>
                  Go To HomePage
                </Button>
          </Grid>
             
          </Grid>
}
          
{registrationFormData.state.dynamicMessage == true > 0 &&
 <Grid container
 spacing={2}
 direction="column"
 alignItems="center"
 justifyContent="center"
 marginTop={'0px'}
 sx={{ minHeight: '50vh' }}>
    <img style={{marginBottom:'1rem',width:'60px'}} src={success}  />
    <p style={{margin:'0px', fontSize:'22px', color:'#606060',lineHeight:'30px',fontWeight: 700, marginBottom:'1rem'}}>Details submitted successfully</p>
    <p style={{margin:'0px', fontSize:'22px',lineHeight:'33px',color:'#606060'}}>{registrationFormData.state.dynamicData}</p>

    <Grid className="Approve_ok" style={{paddingTop: '25px'}}>
                <Button variant="contained" size="medium" color="primary" style={{minWidth: '91px'}}   onClick={navigateToHomePage}>
                  Go To HomePage
                </Button>
          </Grid>
          </Grid>
          
}  

            </div>
    )
}


export default ConfirmationMessage;